import { Table,  TableColumnsType } from 'antd';

  
interface res {
    columns: TableColumnsType<any>,
    data: any
}
export const TableCustom = ( { columns, data } : res) => {
  
    return(
    
        <><Table columns={columns} dataSource={data} pagination={false} style={{marginTop: 10}} /></>
        
    )
}