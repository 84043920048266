import { useContext, useEffect, useState } from "react";
import { PlatformContext } from "../context/platformContext";
import { getData } from "../services/searchServices";

export const useSearchs = () => {
    const [products, setBoards]: any = useState(null)
    const { platformState }: any = useContext(PlatformContext)
    async function data() {
        setBoards([])
        if(localStorage.getItem("session") && platformState.search){
            const dato: any = await getData(platformState.search)
            console.log(dato)
            if(!dato || dato.message === 'Unauthorized'){
                
              return localStorage.clear();
            }        
            return setBoards(dato || [])
        }
      
   
        return setBoards([])
    }
    useEffect(() =>{
        
        data()
       
    },[ platformState.search] )

    return  { products }
}

