import { Col, Input, Row } from "antd"
import { useEffect, useState } from "react";
import { useDebounce } from "../../hooks/debounces";
import Tables from "./table";

export const Search = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, { wait: 500 });
  
    const buscar = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    };
    useEffect(() => {
      if (debouncedSearchTerm) {
        // Aquí realizas la búsqueda con debouncedSearchTerm
        console.log("Buscando:", debouncedSearchTerm);
      }
    }, [debouncedSearchTerm]);
    return(
    <>
        <Row justify="end">
        <Col>
          <Input type="text" placeholder="Buscar..." onChange={buscar} />
        </Col>
        </Row>
        <Tables />
    </>
    )
}