
import {api} from '../environment';

async function  getSurvey() {
    let token = localStorage.getItem('token')
    let sessionTem = localStorage.getItem('session') 
    if (!sessionTem) return
    let { idU, session } = JSON.parse(sessionTem)
    let options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        'session': `${session}`
      }
    }
  
    try {
      
      const login = await fetch(api + "survey/"+idU, options
      );
      const data = await login.json();
      return data;
    } catch (e) {
      return e;
    }
}

async function resposeSurvey(data:any, id: string) {
    let token = localStorage.getItem('token')
    let sessionTem = localStorage.getItem('session') 
    if (!sessionTem) return
    let { session } = JSON.parse(sessionTem)
    let options = {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `${token}`,
        'session': `${session}`
      },
      body: JSON.stringify(data)
    }
  
    try {
  
    
      const login = await fetch(api + "survey/" + id, options
      );
      const data= await login.json();
      return data;
    } catch (e) {
      return e;
    }
}

export {
    getSurvey,
    resposeSurvey
}