import {  Form, Input, Modal, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { getSurvey, resposeSurvey } from "../../services/survey";
import { PlatformContext } from "../../context/platformContext";


const Survey = () => {
  const [form] = Form.useForm();

  const { platformState }: any = useContext(PlatformContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [arrayTem, setArrayTem] = useState([])
  const [arrayResponse, setArrayResponse] = useState<any>(null)

  useEffect(() => {
    getSurvey().then((data: any) => {
      if (data.question?.length > 0) {
        setIsModalOpen(true);
        setQuestions(data.question);
        setArrayResponse(data)
      }
    });
  }, []);



  const handleOk = async() => {
    setIsModalOpen(false);
    let res: any= arrayResponse
 
    let sessionTem = localStorage.getItem('session') 
    if (!sessionTem) return
    let { idU } = JSON.parse(sessionTem)
    let env : any = {
      user: idU,
      question: arrayTem
    }
    let users = res.userRead
    let respon=  res.responses
    users.push(idU)
    respon.push(env)
    let data : any ={
      "updateAt": new Date(),
      "userRead": users,
      "responses": respon
    }
   
   return await resposeSurvey(data, res.uuid)
  };

  const onChange = (nameLabel: any): void => {

    let valuesLabel = form.getFieldValue(nameLabel)
    
    let array: any = questions.slice();
    for(let i in array){
     
      if(array[i].label === nameLabel){
        array[i].response = valuesLabel
        
       return  setArrayTem(array)
      }
    }
   
  
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
    title={platformState.language.surveyTitle}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ padding: 20 }}
      okText="Responder"
      cancelText="Cancelar"
    >
      <>
        <Form form={form} onFinish={handleOk } layout="vertical">
          {questions.map((e: any) => {
            return (
              <>
                {e.type === "select" && (
                  <Form.Item
                    label={e.label}
                    name={e.label}
                    rules={[{ required: true }]}
                  >
                    <Select onChange={()=>onChange(e.label)} >
                      {e.values.map((i: any) => {
                        return <Select.Option value={i.toLowerCase()}>{i}</Select.Option>;
                      })}
                    </Select>
              
                  </Form.Item>
                )}
                {e.type === "select-multiple" && (
                  <Form.Item
                    label={e.label}
                    name={e.label}
                    rules={[{ required: true }]}
                  >
                    <Select onChange={()=>onChange(e.label)} mode="multiple">
                      {e.values.map((i: any) => {
                        return <Select.Option value={i.toLowerCase()}>{i}</Select.Option>;
                      })}
                    </Select>
                    {form.getFieldValue(e.label) === "otro"
                    ?<Input onChange={()=>onChange(e.label)}></Input>
                    :<></>
                    

                    }
                  </Form.Item>
                )}
                          {form.getFieldValue(e.label) === "otro"
                    ?<Form.Item ><Input placeholder="Especifique" onChange={()=>onChange(e.label)}></Input></Form.Item>
                    :<></>
                    

                    }
                {e.type === "text" && (
                  <Form.Item
                    label={e.label}
                    name={e.label}
                    rules={[{ required: true }]}
                  >
                    <Input onChange={()=>onChange(e.label)}></Input>
                  </Form.Item>
                )}
              </>
            );
          })}
        </Form>
      </>
    </Modal>
  );
};

export default Survey;
