
import { Route, Routes } from "react-router-dom";
import ListBoards from "./listBoards";
import ShinyView from "./shinyView";
const Boards = () => {
    return(<>
        
        <Routes >
               
               <Route path="view" element={<ShinyView />} />
               <Route path="/" element={<ListBoards />} />
           </Routes>
    </>)
}

export default Boards;