import { apiBuscador, tokenBuscador } from "../environment"

const getData = async (search: any) => {
    
    let options = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-API-KEY': `${tokenBuscador}`
      }
    }
  
    try {
   
      const login = await fetch(`${apiBuscador}buscador_farmacos?farmaco=${search}`, options);
      const data = await login.json();
      console.log(data)
      return data;
    } catch (e) {
      return e;
    }
  }

  export {
    getData
  }