import { useContext, useEffect } from "react";
import "../../styles/users.css";
import { PlatformContext } from "../../context/platformContext";
import { confirm } from "../../services/alertsServices";
import { Button, Flex, Tag } from "antd";
import Spinner from "../../components/spinner";
import { TableCustom } from "../../components/table";
import {
  DeleteOutlined,
  EditOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import { types } from "../../types/types";
import { useLinks } from "../../hooks/linksAdminHook";

const Tables = () => {
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const { links }: any = useLinks();

  const columns = [
  
    {
      title: platformState.language.linksAdminsUrl,
      dataIndex: "url",
      key: "url",
      render: (url: string) => {
        return (
          <a href={url} target="_black" style={{ textDecoration: "none"}}>{url}</a>
        )
      }
    },
    {
      title: platformState.language.linksAdminsUrlDestino,
      dataIndex: "urlDestino",
      key: "urlDestino",
      render: (urlDestino: string) => {
        return (
          <a href={urlDestino} target="_black">{urlDestino}</a>
        )
      }
    },
    {
      title: platformState.language.linksAdminsDesacriptions,
      dataIndex: "description",
      key: "description",
    },

    {
      title: platformState.language.tableAcciones,
      dataIndex: "uuid",
      key: "uuid",
      render: (uuid: string) => {
        return (
          <Flex gap="small" wrap>
          
            <Button
              onClick={() => {
                borrar(uuid);
              }}
            >
              <DeleteOutlined />
            </Button>
          </Flex>
        );
      },
    },
  ];
  useEffect(() => {}, [platformState.test]);

  const borrar = async (id: string) => {
    return confirm(id, dispatch, "links");
  };
  
  function uploadData(item: any) {
    const data = links.find((m: any) => m.uuid === item)

    dispatch({
      type: types.botonTitle,
      payload: platformState.language.userFormBotonEditar,
    });
    dispatch({
      type: types.title,
      payload: platformState.language.modalCompaniesTitleEdit,
    });
    dispatch({
      type: types.openModal,
      payload: true
    })
    return dispatch({
      type: types.dataUpdate,
      payload: data,
    });
  }
  function formatearFechaCreacion(data: any) {
    let fecha = new Date(data);
    const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1; // Los meses en JavaScript van de 0 a 11, por lo que se suma 1
    const ano = fecha.getFullYear();

    // Formatear en un string con el formato deseado
    return `${dia < 10 ? "0" : ""}${dia}/${mes < 10 ? "0" : ""}${mes}/${ano}`;
  }

  return (
    <>
      {!links ? <Spinner /> : <TableCustom columns={columns} data={links} />}
    </>
  );
};

export default Tables;
