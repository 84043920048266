
import { api } from '../environment';
import { Tracking } from '../interfaces/tracking';
import { types } from '../types/types';
import { error, success } from './alertsServices';
import { postTracking } from './tracking';

let tracking: Tracking = {
  uuidSesion: "",
  activity: "",
  description: ""
}

const getLinks = async (id: string, skip: any, limit: any, search: string) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {
    tracking.activity = "Get Insing"
    tracking.description = "Get all insing"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "links-admins"+ `?limit=${limit}&offset=${skip}&search=${search}`, options
    );
    const data = await login.json();
    return data;
  } catch (e) {
    return e;
  }
}
const getInsingForCompany = async (id: string) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {
    tracking.activity = "Get Insing"
    tracking.description = "Get all insing"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "links-admins/company/" + id, options
    );
    const data = await login.json();
    return data;
  } catch (e) {
    return e;
  }
}

const postLinks = async (data: any) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {
    tracking.activity = "Create Insing"
    tracking.description = "Create a new insing"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "links-admins", options
    );
    const data = await login.json();
    if (data.error) {
      error("No guardo el archivo")
    } else {
      success("Archivo guardado con exito")
    }

    return data;
  } catch (e) {

    error("No guardo el archivo")
    return e;
  }
}

const putLinks = async (data: any, id: string) => {
  console.log(id,data)
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'PATCH',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {
    tracking.activity = "Update Insing"
    tracking.description = "Update a insing"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "links-admins/" + id, options
    );
   
    const data = await login.json();
    if (data.error) {
      error("No guardo el archivo")
    } else {
      success("Archivo guardado con exito")
    }

    return data;
    } catch (e) {
      console.log(e)
    error("No guardo el archivo")
    return e;
  }
}

const deleteLinks = async (id: string, dispatch: any) => {
  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'DELETE',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    }
  }

  try {
    tracking.activity = "Delete Insing"
    tracking.description = "Delete a insing"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "links-admins/" + id, options
    );
    const data = await login.json();
    if (data.error) {

    } else {
      dispatch({
        type: types.test,
        payload: Math.random()
      })
    }

    return data;
  } catch (e) {

    error("No se elimino el link")
    return e;
  }
}

const getInsingForCompanies = async (data: any) => {

  let token = localStorage.getItem('token')
  let sessionTem = localStorage.getItem('session') || ""
  let { session } = JSON.parse(sessionTem)
  let options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `${token}`,
      'session': `${session}`
    },
    body: JSON.stringify(data)
  }

  try {
    tracking.activity = "Get Insing"
    tracking.description = "Get all insing"
    tracking.uuidSesion = session
    await postTracking(tracking)

    const login = await fetch(api + "links-admins/companies", options
    );
    const data = await login.json();


    return data;
  } catch (e) {

    error("No se creo el usuario")
    return e;
  }
}

export {
  getLinks, postLinks, putLinks, deleteLinks, getInsingForCompany, getInsingForCompanies
}