import { useContext, useEffect, useState } from "react";
import { PlatformContext } from "../../context/platformContext";
import { types } from "../../types/types";
import { Card } from "antd";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  EyeOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { getCompanie, getSubCompanies } from "../../services/companiesServices";
import { confirm, selectCompany } from "../../services/alertsServices";
import "../../styles/card.css"

const { Meta } = Card;

const CardBoard = ({ data, role }: any) => {
  let navigate: any = useNavigate();
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const [actions, setActions] = useState<any>([]);
  useEffect(() => {

    if (role > 2)
      setActions([
        <EyeOutlined key="eye" onClick={() => {return navigations("view", "eye")}} />,
        <EditOutlined
          key="setting"
          onClick={() => {return  navigations("view", "setting")}}
        />,
        <DeleteOutlined  key={"delete"} onClick={()=>{return deleteBoard()}}/>
      ]);
  }, [role]);

  async function loadShiny() {
    let user = JSON.parse(localStorage.getItem("session") || "");
    
    dispatch({
      type: types.uuidBoard,
      payload: data.uuid
    })
    if (user) {
      dispatch({
        type: types.urlShinny,
        payload: data.url,
      });
 
      let index = user.boards.map((e: any) => e.uuid).indexOf(data.uuid);
      if (index > -1) {
        const hijos = await getSubCompanies(user.boards[index].uuidEmpresa);
        if (hijos && hijos.length > 0) {
          const array = hijos.map((e: any) => e.name);

          return await selectCompany(array, hijos, dispatch, navigate);
        }
        const company = await getCompanie(user.boards[index].uuidEmpresa);

        await dispatch({
          type: types.urlShinny,
          payload: data.url,
        });
        await dispatch({
          type: types.company,
          payload: company,
        });
     
        return navigate("view", { state: { item: user.boards[index].items } });
      }
    }
  }

  function uploadData(item: any) {
    dispatch({
      type: types.botonTitle,
      payload: platformState.language.userFormBotonEditar,
    });
    dispatch({
      type: types.botonTitle,
      payload: platformState.language.userFormBotonEditar,
    });

    dispatch({
      type: types.title,
      payload: platformState.language.modalBoardsEditar,
    });
    dispatch({
      type: types.openModal,
      payload: true
    })
    return dispatch({
      type: types.dataUpdate,
      payload: item,
    });
  }
  const navigations = async (path: string, key: string | null = null) => {
    
    if (key === "eye" ) {
      
      return await loadShiny();
    }
    return await uploadData(data);
  };

  const deleteBoard = async() =>{

    confirm(data.uuid, dispatch, "boards")
  }
  return (
    <Card
      title={data.name}
      hoverable
      style={{ height: 360, marginTop: "1rem", marginBottom: "1rem" }}
      cover={
        <img
          style={{ 
            width: "100%",
            height: "100%",
            objectFit: "contain",
            aspectRatio: "16 / 9" 

            }}
          alt="example"
          src={data.image}
        />
      }
      actions={actions}
      onClick={() => { if(role < 2) return navigations("view", "eye")  } }
    >
      <Meta
      
        
        description={<div className="card-meta-ellipsis">{data.description || "..."}</div>}
      />
    </Card>
  );
};

export default CardBoard;
