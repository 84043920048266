import { Avatar, Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { horaToday } from "../hooks/times";
import { PlatformContext } from "../context/platformContext";
import { useNavigate } from "react-router-dom";
import { IconHeader } from "./icons/iconHeader";
import { types } from "../types/types";


const HeaderCustom = () => {
  let history: any = useNavigate();
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const [horas, setHoras] = useState<any>("");

  const image = (): string => {
    let tem = localStorage.getItem("session") 
    if(!tem)  {
      window.location.replace("/")
      return ""
    }else {
       let user = JSON.parse(tem)
    return user?.image || ""
    }
   
  }
  useEffect(() => {
    
    setHoras(horaToday("es"));
    setTimeout(() => {
      setHoras(horaToday("es"));
    }, 60000);
  }, []);
  const loadTitle = (ruta: string) => {
    dispatch({
      type: types.ruta,
      payload: ruta,
    });
  };
  return (
    <Row justify="space-between" className="content-wrapper">
      <Col span={8} style={{ paddingLeft: "2rem"}}>
        <h2 style={{ margin: "-50vh !important", marginBottom: "0%" }}>
          {platformState.ruta}
        </h2>
        <small
        
          className="subTitle"
        >
          {horas}
        </small>
      </Col>

      <Col span={4} style={{ textAlign: "center",  paddingTop: "0rem" }}>
        <Avatar
          style={{ cursor: "pointer"}}
          size={{ xs: 32, sm: 42, md: 64, lg: 64, xl: 70, xxl: 70 }}
          icon={<IconHeader url={image()} />}
          onClick={()=> {
            history(`${platformState.language.profile}`);
            loadTitle(platformState.language.profile)
          }}
        />
      </Col>
    </Row>
  );
};

export default HeaderCustom;
