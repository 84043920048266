import { useContext, useEffect } from "react";
import { success } from "../../services/alertsServices";
import Tables from "./table";
import { PlatformContext } from "../../context/platformContext";

import { types } from "../../types/types";
import { Col, Input, Row } from "antd";
import Paginator from "../../components/paginator";

const LinksAdmins = () => {
    const { platformState, dispatch }: any = useContext(PlatformContext)
    

    useEffect(() => {


    }, [
        dispatch
    ])

    function buscar(value: string) {
        dispatch
            ({
                type: types.search,
                payload: value
            })
    }
    return (
        <>
        <Row justify="end">
          <Col>
            <Input
              type="text"
              placeholder="Buscar..."
              onChange={(e) => buscar(e.target.value)}
            />
          </Col>
  
          <Col style={{ marginInline: 15 }}>
            <Paginator />
          </Col>
        </Row>
  
        <Tables />
      </>
    )
}

export default LinksAdmins;