import { useContext, useEffect, useState } from "react";
import { getUsers } from "../services/usersServices";
import { PlatformContext } from "../context/platformContext";





export const useUsers = () => {
    const { platformState, dispatch }: any = useContext(PlatformContext)
    const [users, setBoards]: any = useState(null)

   const  data = async(id: any) => {

        const data = await getUsers(id, platformState.skip, platformState.limit, platformState.search)
       
       
        if(data.message === 'Unauthorized'){
            
            return  localStorage.clear()
          }
        return setBoards(data || [])
    }
    useEffect(() =>{
        let datas = localStorage.getItem("session") || ""
        let id : any = JSON.parse(datas)
        
        if(id){
            
                data(id.uuid)   
        }
       
       
    },[platformState.opeModal, platformState.skip, platformState.search, platformState.test] )

    return  { users }
}

