import { Avatar, Button, Form, Input, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import { PlatformContext } from "../../context/platformContext";
import { pdfImage } from "../../environment";
import { types } from "../../types/types";
import { postLinks, putLinks } from "../../services/linksServices";

const FormsLinks = ({ close }: any) => {
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const [form] = Form.useForm();
  const [image, setImage] = useState(pdfImage);

  useEffect(() => {}, [platformState.openModal, platformState.dataUpdate]);

  const action = async () => {
    if (platformState.dataUpdate) return await updateData();
    return await sendData();
  };

  const sendData = async () => {
    let { url, urlDestino, description } = form.getFieldsValue();

    let env = {
      url: url,
      description: description,
      urlDestino: urlDestino,
    };

    await postLinks(env);

    limpiar();
    return close();
  };
  const updateData = async () => {
    let { url, urlDestino, description } = form.getFieldsValue();

    let env = {
      url: url,
      description: description,
      urlDestino: urlDestino,
    };

    await putLinks(env, platformState.dataUpdate.uuid);

    limpiar();
    return close();
  };
  function limpiar() {
    form.resetFields();
    setImage(pdfImage);

    return dispatch({
      type: types.test,
      payload: Math.random(),
    });
  }
  return (
    <>
      <Form
        name="wrap"
        labelCol={{ flex: "110px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        form={form}
        onFinish={action}
        style={{ textAlign: "center" }}
      >
        <Form.Item
          label={platformState.language.linksAdminsUrl}
          name="url"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label={platformState.language.linksAdminsUrlDestino}
          name="urlDestino"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label={platformState.language.linksAdminsDesacriptions}
          name="description"
          rules={[{ required: true }]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item label=" " style={{ textAlign: "end" }}>
          <Button type="primary" htmlType="submit">
            {platformState.botonModalForm}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormsLinks;
