import { getdata, sendLogin } from "../services/authServices"


const login = async (data:any) => {
    
    const callData = await sendLogin(data);

   
    return callData;

}

const getMain = async (id: string) => {
    const data = await getdata(id)
    return data
}
export {
    login,
    getMain
}