/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect } from "react";
import "../../styles/users.css";
import { PlatformContext } from "../../context/platformContext";
import Spinner from "../../components/spinner";
import { TableCustom } from "../../components/table";
import { useSearchs } from "../../hooks/searchsHook";

const Tables = () => {
  const { platformState, dispatch }: any = useContext(PlatformContext);
  const { products }: any = useSearchs()

  const columns = [
    {
      title: platformState.language.tableName,
      dataIndex: "name",
      key: "name",
    },
    {
      title: platformState.language.searchPrice,
      dataIndex: "price",
      key: "price",
    },
    {
      title: platformState.language.searchLocal,
      dataIndex: "local",
      key: "local",
    }
  ];

  useEffect(() => {}, [platformState.test]);



  return (
    <>
      {!products ? (
        <Spinner />
      ) : (
        <TableCustom columns={columns} data={products} />
      )}
    </>
  );
};

export default Tables;
